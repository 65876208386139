import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import './CookieManager.css'; 

const generateUniqueId = () => {
  return `user-${Math.random().toString(36).substr(2, 9)}`;
};

const getOrCreateUserId = () => {
  let userId = Cookies.get('userId');
  if (!userId) {
    userId = generateUniqueId();
    Cookies.set('userId', userId, { expires: 365 });
  }
  return userId;
};

const trackEvent = (eventType, details) => {
  const userId = getOrCreateUserId();
  if (Cookies.get('consent') === 'true') {
    console.log(`Tracking event: ${eventType}`, { userId, ...details });
  }
};

const CookieManager = () => {
  const [consentGiven, setConsentGiven] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('consent');
    setConsentGiven(consent === 'true');

    if (consent === 'true') {
      trackEvent('page_load', { path: window.location.pathname });
      
      const handleClick = (e) => {
        trackEvent('click', { x: e.clientX, y: e.clientY, element: e.target.tagName });
      };

      document.addEventListener('click', handleClick);
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }
  }, [consentGiven]);


  const handleConsent = (consent) => {
    Cookies.set('consent', consent, { expires: 365 });
    setConsentGiven(consent === 'true');
  };

  return (
    !consentGiven && (
      <div className="cookie-manager-container">
        <div className="cookie-manager-banner">
          <p>We use cookies to enhance your experience. Do you accept our cookie policy?</p>
          <button onClick={() => handleConsent('true')}>Accept</button>
          <button onClick={() => handleConsent('false')}>Decline</button>
        </div>
      </div>
    )
  );
};

export default CookieManager;
