import React, { useEffect, useRef, useState } from 'react';
import './Seasonal.css';
import { useNavigate } from 'react-router-dom';
import { useFoodContext } from '../../../context/FoodContext';



const Seasonal = () => {
  const [isVisible] = useState(false);
  const seasonFavRef = useRef(null);


  const foods = [
    { id: 1, name: 'Grilled Salmon', description: 'Delicious grilled salmon fillets with a lemon herb seasoning.', img: 'https://plus.unsplash.com/premium_photo-1723478417559-2349252a3dda?q=80&w=1966&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 2, name: 'Caesar Salad', description: 'Crisp romaine lettuce tossed with Caesar dressing, croutons, and Parmesan cheese.', img: 'https://images.unsplash.com/photo-1561043433-aaf687c4cf04?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 3, name: 'BBQ Ribs', description: 'Tender pork ribs smothered in a smoky BBQ sauce.', img: 'https://images.unsplash.com/photo-1593030668930-8130abedd2b0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 4, name: 'Vegetable Stir-Fry', description: 'A mix of fresh vegetables stir-fried in a savory sauce.', img: 'https://images.unsplash.com/photo-1552914343-54bcc3ba07f8?q=80&w=1984&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 5, name: 'Tacos', description: 'Seasoned beef tacos with lettuce, cheese, and salsa.', img: 'https://images.unsplash.com/photo-1615870216519-2f9fa575fa5c?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 6, name: 'Pasta Alfredo', description: 'Creamy Alfredo pasta with Parmesan cheese and a touch of garlic.', img: 'https://images.unsplash.com/photo-1473093226795-af9932fe5856?q=80&w=1994&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 7, name: 'Chicken Parmesan', description: 'Breaded chicken breast topped with marinara sauce and melted cheese.', img: 'https://www.kitchensanctuary.com/wp-content/uploads/2020/01/Chicken-Parmesan-square-FS-38.jpg' },
    { id: 8, name: 'Apple Pie', description: 'Classic apple pie with a flaky crust and spiced apple filling.', img: 'https://images.unsplash.com/photo-1601000938259-9e92002320b2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
  ];


  const {food,setFood} = useFoodContext();
  const navigate = useNavigate();
   
   const handleNavigation = (value) =>{
     setFood(value);
     navigate(`/recipe/trending/${value.name}`); 
   }


  useEffect(() => {
    const currentRef = seasonFavRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      ref={seasonFavRef}
      className={` food-list ${isVisible ? 'visible' : ''}`}
    >
      <h2>Seasonal Favorites</h2>
      <p>Explore our seasonal recipe collections and find the perfect dishes for every occasion.</p>
      <div className='food-items'>
        {foods.map(food => (
          <div key={food.id} className='food-item' onClick={()=>handleNavigation(food)}>
            <div className="food-item-img">
              <img src={food.img} alt={food.name} className='food-image' />
            </div>
            <div className="food-item-info">
              <h3>{food.name}</h3>
              <p>{food.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Seasonal;
