import React from 'react';
import './Terms.css';

const Terms = () => {
  return (
    <section className="terms-container">
      <h2>Terms and Conditions</h2>
      
      <div className="terms-section">
        <h3>Introduction</h3>
        <p>Welcome to our website. By accessing and using this website, you agree to comply with and be bound by the following terms and conditions of use. If you disagree with any part of these terms and conditions, please do not use our website.</p>
      </div>

      <div className="terms-section">
        <h3>Intellectual Property</h3>
        <p>The content on this website, including but not limited to text, graphics, logos, and images, is the property of our website and is protected by intellectual property laws. Unauthorized use of this content is prohibited.</p>
      </div>

      <div className="terms-section">
        <h3>User Responsibilities</h3>
        <p>As a user of this website, you agree to use the website in a lawful manner. You are responsible for ensuring that your use of the website does not violate any applicable laws or regulations.</p>
      </div>

      <div className="terms-section">
        <h3>Limitation of Liability</h3>
        <p>We are not liable for any damages that may arise from the use of this website. This includes, but is not limited to, direct, indirect, incidental, and consequential damages.</p>
      </div>

      <div className="terms-section">
        <h3>Changes to Terms</h3>
        <p>We reserve the right to modify these terms and conditions at any time. Changes will be effective immediately upon posting to the website. It is your responsibility to review these terms regularly to ensure you are aware of any updates.</p>
      </div>

      <div className="terms-section">
        <h3>Contact Information</h3>
        <p>If you have any questions about these terms and conditions, please contact us at contact@foodrecipes.com.</p>
      </div>
    </section>
  );
}

export default Terms;
