import React from 'react';
import './Footer.css'; 
import { useLocalContext } from '../../../context/LocalContext';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const {local} = useLocalContext();
    const navigate = useNavigate();

    const handleNavigation = (value) =>{
     navigate(value);
    }

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-brand">
          <h2>{local.siteName}</h2>
          <p>
          "Savor the moment, cherish the flavors, and let every bite tell a story of delight and indulgence."
          </p>
        </div>
        <div className="footer-links">
          <div className="footer-column">
            <h3>{local.siteName}</h3>
            <ul>
              <li onClick={()=>handleNavigation('/about')}>About us</li>
              <li onClick={()=>handleNavigation('/contact')}>Contact us</li>
              <li onClick={()=>handleNavigation('/contact')}>FeedBack</li>
            </ul>
          </div>
          <div className="footer-column">
            <h3>Legal</h3>
            <ul>
              <li onClick={()=>handleNavigation('/terms&condition')}>Terms & Conditions</li>
              <li onClick={()=>handleNavigation('/privacypolicy')}>Privacy Policy</li>

            </ul>
          </div>
          <div className="footer-column">
            <h3>Follow</h3>
            <ul>
              <li>Facebook</li>
              <li>Twitter</li>
              <li>Instagram</li>
              <li>Youtube</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 {local.siteName} - All rights reserved</p>
        <div className="social-icons">
          <i className="fab fa-facebook-f"></i>
          <i className="fab fa-instagram"></i>
          <i className="fab fa-twitter"></i>
          <i className="fab fa-youtube"></i>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
