import React from 'react'
import './Trending.css'
import './subCategory.css'
import { useFoodContext } from '../../../context/FoodContext';
import { useNavigate } from 'react-router-dom';

const Trending = () => {

    const americanFoods = [
        { id: 1, name: 'Cheeseburger', description: 'A classic American cheeseburger with a juicy beef patty, melted cheese, lettuce, tomato, and pickles.',img:"https://images.unsplash.com/photo-1498579485796-98be3abc076e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { id: 2, name: 'Buffalo Wings', description: 'Spicy chicken wings coated in a tangy buffalo sauce, served with celery and ranch dipping sauce.',img:"https://images.unsplash.com/photo-1631897788978-da06ec45adcb?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { id: 3, name: 'Apple Pie', description: 'A traditional American dessert made with spiced apples and a flaky pie crust.',img:"https://plus.unsplash.com/premium_photo-1666353535340-4819e944fd75?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" },
        { id: 4, name: 'Cornbread', description: 'A classic Southern cornbread with a slightly sweet flavor and a crumbly texture.', img: 'https://images.unsplash.com/photo-1565311628541-6e434be62ea5?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
        { id: 5, name: 'Clam Chowder', description: 'A creamy soup made with clams, potatoes, and onions, often enjoyed with oyster crackers.', img: 'https://images.unsplash.com/photo-1593854823220-267730770d09?q=80&w=1852&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
        { id: 6, name: 'BBQ Ribs', description: 'Tender pork ribs slathered in a smoky barbecue sauce and grilled to perfection.', img: 'https://images.unsplash.com/photo-1504564321107-4aa3efddb5bd?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
        { id: 7, name: 'Pancakes', description: 'Fluffy breakfast pancakes served with butter and maple syrup.', img: 'https://images.unsplash.com/photo-1521401187146-3a5118db5945?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
        { id: 8, name: 'Fried Chicken', description: 'Crispy and golden fried chicken with a seasoned breading, a Southern staple.', img: 'https://images.unsplash.com/photo-1569691899455-88464f6d3ab1?q=80&w=1886&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
     ];

     const {food,setFood} = useFoodContext();
     const navigate = useNavigate();
      
      const handleNavigation = (value) =>{
        setFood(value);
        navigate(`/recipe/trending/${value.name}`); 
      }

  return (
    <section className="trending food-list">
    <h2>Trending Recipes</h2>
    <p>Find recipes for every trending occasion and taste.</p>
  <div className="food-items">
  {americanFoods.map(food => (
     <div key={food.id} className="food-item" onClick={()=>handleNavigation(food)}>
      <img src={food.img} alt={food.name} className="food-image" />
       <h3>{food.name}</h3>
        <p>{food.description}</p>
      </div>
    ))}
  </div>
</section>
  )
}

export default Trending