import React from 'react';
import './About.css';

const About = () => {
  return (
    <section className="about-container">
      <h2>About Me</h2>
      
      <div className="about-section">
        <h3>Who Am I?</h3>
        <p>Hi there! I’m a culinary enthusiast and a storyteller at heart. My journey into the world of cooking began as a quest for flavors and quickly turned into a love affair with food. From experimenting with new ingredients to perfecting classic dishes, I find joy in every aspect of cooking. For me, food is not just about taste—it's about creating experiences that bring people together.</p>
      </div>

      <div className="about-section">
        <h3>What I Offer</h3>
        <p>Through this platform, I aim to share not just recipes but also tips, tricks, and insights to make your cooking journey more enjoyable. From easy weekday meals to show-stopping dishes for special occasions, you’ll discover a collection of recipes designed to inspire and empower you in the kitchen. Let's cook together, explore new flavors, and make every meal memorable.</p>
      </div>
    </section>
  );
}

export default About;
