import React, { useEffect } from 'react';

export const RedirectHandler = ({ link }) => {
  useEffect(() => {
    if (link) {
      window.location.href = link;
    }
  }, [link]);

  return null;
};
