import React, { useState } from 'react';
import './Header.css';
import { useLocalContext } from '../../../context/LocalContext';
import { useNavigate } from 'react-router-dom';
import { useFoodContext } from '../../../context/FoodContext';

const Header = () => {
  const { local } = useLocalContext();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { setSubCategory } = useFoodContext();

  const categories = [
    { name: "Trending Recipe", route: "trending" },
    { name: "Seasonal Favorites", route: "season_fav" },
    // { name: "Third-Culture Cuisine", route: "Third-Culture Cuisine" },
    { name: "Vegan", route: "vegan" },
    { name: "Cakes", route: "cakes" },
    // { name: "Healthy", route: "healthy" },
    { name: "Quick & Easy", route: "quick_easy" },
    { name:"Dessert Food",route:"dessert_food" }
  ];

  const handleNavigation = (name, route) => {
    navigate(`/recipe/${route.toLowerCase()}`);
    setSubCategory(name);
    setDropdownOpen(false);
  };

  return (
    <header className="header">
     
      <div className="nav-section">
      <div className="top-section">
       <a href="/" style={{textDecoration:"none"}}> <div className="logo" >{local.siteName}</div></a>
      </div>
       

        <nav className="nav">
          <ul className="nav-list">
            <li className="nav-item" onClick={() => navigate('/')}>Home</li>
            <li className="nav-item dropdown">
              <div className="dropbutn" onClick={() => setDropdownOpen(!dropdownOpen)}>Recipe</div>
              <div className={`dropdown-content ${dropdownOpen ? 'show' : ''}`}>
                {categories.map((category) => (
                  <div
                    key={category.route}
                    className="dropdown-item"
                    onClick={() => handleNavigation(category.name, category.route)}
                  >
                    <p>{category.name}</p>
                  </div>
                ))}
              </div>
            </li>
            <li className="nav-item" onClick={() => navigate('/about')}>About</li>
            <li className="nav-item" onClick={() => navigate('/contact')}>Contact</li>
            <li className="nav-item" onClick={() => navigate('/terms&condition')}>T&C</li>
          </ul>
        </nav>
        <div className="social-media">
          <i className="fab fa-facebook-f fa-2x"></i>
          <i className="fab fa-instagram fa-2x"></i>
          <i className="fab fa-twitter fa-2x"></i>
          <i className="fab fa-youtube fa-2x"></i>
        </div>
        {/* <div className="search-icon">
          <i className="fa fa-search"></i>
        </div> */}
        {/* <div className="hamburger-menu">
          <i className="fa fa-bars"></i>
        </div> */}
      </div>
    </header>
  );
};

export default Header;
