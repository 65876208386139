import React, { useEffect, useRef, useState } from 'react';
import './Vegan.css';
import { useNavigate } from 'react-router-dom';
import { useFoodContext } from '../../../context/FoodContext';

const Vegan = () => {
  const [isVisible, setIsVisible] = useState(false);
  const veganRef = useRef(null);
  const {food,setFood} = useFoodContext();

  const veganFoods = [
    { id: 1, name: 'Quinoa Salad', description: 'A refreshing salad with quinoa, veggies, and a lemon vinaigrette.', img: 'https://images.unsplash.com/photo-1623428187969-5da2dcea5ebf?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 2, name: 'Veggie Burger', description: 'A delicious veggie patty with lettuce, tomato, and a special sauce.', img: 'https://images.unsplash.com/photo-1536304494833-02c3abf8bd4c?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 3, name: 'Stuffed Bell Peppers', description: 'Bell peppers filled with a mix of rice, beans, and spices.', img: 'https://images.unsplash.com/photo-1511690656952-34342bb7c2f2?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 4, name: 'Vegan Tacos', description: 'Tacos filled with spiced lentils, avocado, and fresh salsa.', img: 'https://images.unsplash.com/photo-1703073185774-a38e4e68c205?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 5, name: 'Chickpea Curry', description: 'A rich and flavorful curry with chickpeas and vegetables.', img: 'https://images.unsplash.com/photo-1609595781576-1580ff3ee291?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 6, name: 'Sweet Potato Fries', description: 'Crispy sweet potato fries seasoned with paprika and garlic.', img: 'https://images.unsplash.com/photo-1725006015031-04bf253ae9f7?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 7, name: 'Avocado Toast', description: 'Toasted bread topped with creamy avocado, cherry tomatoes, and herbs.', img: 'https://images.unsplash.com/photo-1687276287139-88f7333c8ca4?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
    { id: 8, name: 'Berry Smoothie', description: 'A refreshing smoothie made with mixed berries and a splash of almond milk.', img: 'https://images.unsplash.com/photo-1494315153767-9c231f2dfe79?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' },
  ];

  const navigate = useNavigate();

  const handleNavigation = (value) => {
    setFood(value);
    navigate(`/recipe/vegan/${value.name}`);
  };

  useEffect(() => {
    const currentRef = veganRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section
      id='vegan'
      ref={veganRef}
      className={`vegans food-list ${isVisible ? 'visible' : ''}`}
    >
      <h2>Vegan Delights</h2>
      <p>Discover our delicious vegan recipes that are both healthy and satisfying.</p>
      <div className='food-items'>
        {veganFoods.map(food => (
          <div key={food.id} className='food-item' onClick={() => handleNavigation(food)}>
            <div className="food-item-img">
              <img src={food.img} alt={food.name} className='food-image' />
            </div>
            <div className="food-item-info">
              <h3>{food.name}</h3>
              <p>{food.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Vegan;
