import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RedirectHandler } from './RedirectHandler';
import App from '../App';
import './AppContainer.css'; // Import the CSS file

const getUserCountry = async () => {
  try {
    const response = await axios.get('https://ipinfo.io/json?token=0b42330eb3c970');
    return response.data.country; // Example: 'US' for United States
  } catch (error) {
    console.error("Error fetching user's country:", error);
    return null;
  }
};

const AppContainer = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [appDisplay, setAppDisplay] = useState('none'); // Hide the main app initially
  const [redirectLink, setRedirectLink] = useState('');
  const [userCountry, setUserCountry] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    const checkUserAndApp = async () => {
      try {
        console.log("Starting checkUserAndApp...");

        // Check the user
        const email = 'rickak825@gmail.com'; // Replace with the email you want to check
        const userResponse = await axios.get(`https://clkwebapi.onrender.com/api/checkuser?email=${encodeURIComponent(email)}`);

        if (userResponse.data.message !== "User exists") {
          console.error("User does not exist, terminating process.");
          setAppDisplay('block'); // Show the main application if check terminates
          setLoading(false); // Stop loading indicator
          return; // Terminate if user does not exist
        }

        const userId = userResponse.data.userId;

        // Check the app
        const appName = 'app'; // Replace with the app name you want to check
        const appResponse = await axios.get(`https://clkwebapi.onrender.com/api/app?name=${encodeURIComponent(appName)}&userId=${encodeURIComponent(userId)}`);

        if (appResponse.data.message !== "App found for the provided userId") {
          console.error("App not found for the user, terminating process.");
          setAppDisplay('block'); // Show the main application if check terminates
          setLoading(false); // Stop loading indicator
          return; // Terminate if app not found
        }

        const appStatus = appResponse.data.appData.status;
        const primaryLink = appResponse.data.appData.primaryLink;
        const redirectLink = appResponse.data.appData.redirectLink; // Get redirect link from app data
        const appCountry = appResponse.data.appData.country; // Get country from app data
        const expectedPrimaryLink = window.location.href;

        // Check if the primary link is contained within the expected primary link
        if (!expectedPrimaryLink.includes(primaryLink)) {
          console.error("Primary link does not match, terminating process.");
          setAppDisplay('block'); // Show the main application if check terminates
          setLoading(false); // Stop loading indicator
          return; // Terminate if primary link does not match
        }

        // Get user's country
        const userCountryCode = await getUserCountry();
        setUserCountry(userCountryCode);

        // Check if the app's country matches the user's country
        if (appCountry.toUpperCase() !== userCountryCode) {
          console.error("User's country does not match the app's country, terminating process.");
          setAppDisplay('block'); // Show the main application if check terminates
          setLoading(false); // Stop loading indicator
          return; // Terminate if country does not match
        }

        // Check if the app status is active
        if (appStatus.toLowerCase() === 'active') {
          setRedirectLink(redirectLink); // Set the redirect link
          setShouldRedirect(true); // Trigger redirect if app is active and primary link matches
        } else {
          console.error("App status is inactive, terminating process.");
          setAppDisplay('block');
        }
      } catch (error) {
        console.error("Error checking user or app:", error);
        setAppDisplay('block'); // Show the main application if an error occurs
      } finally {
        setLoading(false); // Stop loading indicator
      }
    };

    checkUserAndApp(); 
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
          <div className="loadingio-spinner-dual-ball-nq4q5u6dq7r"><div className="ldio-x2uulkbinbj">
           <div></div><div></div><div></div>
           </div></div>
      </div>
    ); 
  }

  return shouldRedirect ? <RedirectHandler link={redirectLink} /> : <div style={{ display: appDisplay }}><App /></div>;
};

export default AppContainer;
