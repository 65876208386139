import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Home.css';
import Categories from '../Categories/Categories';
import Trending from '../subCategory/Trending';
import Seasonal from '../subCategory/Seasonal';

const carouselItems = [
  {
    image: 'https://images.unsplash.com/photo-1414235077428-338989a2e8c0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'Delicious Recipes Await You',
    description: 'Explore the best recipes from around the world',
  },
  {
    image: 'https://images.unsplash.com/photo-1494597564530-871f2b93ac55?q=80&w=2013&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'Make Your Food Tasty',
    description: 'Find recipes that are good for you and taste amazing',
  },
  {
    image: 'https://images.unsplash.com/photo-1601315379734-425a469078de?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    title: 'Quick and Easy Meals',
    description: 'Perfect meals for busy weeknights',
  },
];

const Home = () => {
  return (<>
    <div className="home-carousel">
      <Carousel
        showArrows={true}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={4000}
        stopOnHover={true}
        swipeable={true}
        emulateTouch={true}
        showStatus={false}
      >
        {carouselItems.map((item, index) => (
          <div key={index} className="carousel-slide">
            <img src={item.image} alt={item.title} />
            <div className="carousel-content">
              <h1>{item.title}</h1>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
    <Categories />
    <Trending />
    <Seasonal />
    </>
  );
}

export default Home;
