import React from 'react';
import './Categories.css';
import { useFoodContext } from '../../../context/FoodContext';
import { useNavigate } from 'react-router-dom';

const categories = [
  {
    image: 'https://images.pexels.com/photos/27097135/pexels-photo-27097135/free-photo-of-soup-served-in-a-restaurant.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: 'Trending Recipe',
    route: "trending"
  },
  {
    image: 'https://images.pexels.com/photos/19786212/pexels-photo-19786212/free-photo-of-table-full-of-fast-food-and-beer.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: 'Seasonal Favorites',
    route: "season_fav"
  },
  // {
  //   image: 'https://images.unsplash.com/photo-1713667417761-408ec68c3af1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  //   name: 'Third-Culture Cuisine',
  // },
  {
    image: 'https://images.unsplash.com/photo-1601000938259-9e92002320b2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    name: 'Vegan',
    route: "vegan"
  },
  {
    image: 'https://images.pexels.com/photos/6133303/pexels-photo-6133303.jpeg?auto=compress&cs=tinysrgb&w=600',
    name: 'Cakes',
    route: "cakes"
  },
  // {
  //   image: 'https://images.pexels.com/photos/775032/pexels-photo-775032.jpeg?auto=compress&cs=tinysrgb&w=600',
  //   name: 'Healthy',
  // },
  {
    image: 'https://images.unsplash.com/photo-1478369402113-1fd53f17e8b4?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    name: 'Quick & Easy',
    route: "quick_easy"
  },
  {
    image: 'https://images.pexels.com/photos/291528/pexels-photo-291528.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    name: 'Dessert Food',
    route:"dessert_food"
  },
];

const Categories = () => {

  const {setSubCategory} = useFoodContext();
  const navigate = useNavigate();

  const handleNavigation = (name,route) =>{
    setSubCategory(name);
    navigate(`/recipe/${route.toLowerCase()}`);
  }

  return (
    <div>

      <div className="category-section">
        <div className="cate">
        {categories.map((category, index) => (
          <div key={index} className="category-card" onClick={()=>handleNavigation(category.name,category.route)}>
            <img src={category.image} alt={category.name} className="category-image" />
            <p className="category-name">{category.name}</p>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default Categories;
