import React from 'react';
import './OpenCategory.css';
import { useFoodContext } from '../../../context/FoodContext';
import Seasonal from '../subCategory/Seasonal';
import Trending from '../subCategory/Trending';
import Cakes from '../subCategory/Cakes';
import Vegan from '../subCategory/Vegan';
import Quick from '../subCategory/Quick';
import Dessert from '../subCategory/Dessert';



const OpenCategory = () => {
  const { subCategory } = useFoodContext();

  const renderComponent = () => {
    switch (subCategory) {
      case 'Trending Recipe':
        return <Trending />;
      case 'Seasonal Favorites':
        return <Seasonal />;
      case 'Cakes':
          return <Cakes />;
          case 'Vegan':
            return <Vegan />;
           case 'Quick & Easy':
            return <Quick /> 
            case 'Dessert Food':
            return <Dessert /> 
      default:
        return <p className='mid'>Currently Not Available, We are Adding Soon!!🙂</p>;
    }
  };

  return (
    <div className="opencategory">
      {renderComponent()}
    </div>
  );
};

export default OpenCategory;
