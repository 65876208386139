import React, { useEffect, useRef, useState } from 'react';
import './Quick.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useFoodContext } from '../../../context/FoodContext';

const Quick = () => {
  const [isVisible, setIsVisible] = useState(false);
  const hamRef = useRef(null);
  const {food,setFood} = useFoodContext();

  const hamFoods = [
    { 
      id: 1, 
      name: 'Honey Glazed Ham', 
      description: 'A sweet and savory ham glazed with honey and brown sugar.', 
      img: 'https://images.unsplash.com/photo-1478369402113-1fd53f17e8b4?q=80&w=1920&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' 
    },
    { 
      id: 2, 
      name: 'Smoked Ham', 
      description: 'Delicately smoked ham with a rich and smoky flavor.', 
      img: 'https://www.ozpig.com.au/assets/webshop/cms/58/258-1.png?1672289816' 
    },
    { 
      id: 3, 
      name: 'Maple Bourbon Ham', 
      description: 'Ham glazed with a maple bourbon sauce, perfect for a festive meal.', 
      img: 'https://images.unsplash.com/photo-1586190848861-99aa4a171e90?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3' 
    },
    { 
      id: 4, 
      name: 'Pineapple Baked Ham', 
      description: 'A classic baked ham topped with pineapple rings and cherries.', 
      img: 'https://www.thespruceeats.com/thmb/pJ-RmloPcaiOO7Rxw5mgZ6aHWA0=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/baked-ham-with-pineapple-3051040-hero-01-56776b7532ba42fe91194aede1f7a8f0.jpg' 
    },
  ];

  const navigate = useNavigate();

  const handleNavigation = (food) => {
    setFood(food);
    navigate(`/recipe/quick_easy/${food.name}`);
  };

  useEffect(() => {
    const currentRef = hamRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          setIsVisible(true);
        } else {
          entry.target.classList.remove('visible');
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  const location = useLocation();


  const sectionStyle = {
    minHeight: location.pathname !== '/' ? '622px' : 'auto'
  };

  return (
    <section
      id='ham'
      ref={hamRef}
      className={`hams food-list ${isVisible ? 'visible' : ''}`}
      style={sectionStyle}
    >
      <h2>Quick & Easy</h2>
      <p>Explore our selection of Quick & Easy dishes, perfect for any occasion.</p>
      <div className='food-items'>
        {hamFoods.map(food => (
          <div key={food.id} className='food-item' onClick={() => handleNavigation(food)}>
            <div className="food-item-img">
              <img src={food.img} alt={food.name} className='food-image' />
            </div>
            <div className="food-item-info">
              <h3>{food.name}</h3>
              <p>{food.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Quick;
