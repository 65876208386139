import React from 'react';
import Container from './Components/container/Container';
import { LocalProvider } from './context/LocalContext';
import './assets/styles.css';
import { FoodProvider } from './context/FoodContext';

const App = () => (
  <FoodProvider>
    <LocalProvider>
      <Container />
    </LocalProvider>
  </FoodProvider>
);

export default App;
