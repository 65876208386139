import React from 'react';
import './Recipe.css';
import { useParams } from 'react-router-dom';
import { useFoodContext } from '../../../context/FoodContext';

const Recipe = () => {
  const { recipeName } = useParams();
  const { food } = useFoodContext();

  if (!food || food.name !== recipeName) {
    return <p className="not-found">Recipe not found</p>;
  }

  return (
    <div className="recipe-card">
      <img src={food.img} alt={food.name} className="recipe-image-card" />
      <div className="recipe-content-card">
        <h2>{food.name}</h2>
        <p>{food.description}</p>
        <div className="recipe-steps">
          <h3>Preparation Steps</h3>
          <ol>
            <li>Gather all ingredients.</li>
            <li>Prepare the {food.name} according to the recipe.</li>
            <li>Cook or bake as required.</li>
            <li>Serve and enjoy!</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default Recipe;
