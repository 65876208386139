import React from 'react';
import './Cakes.css';
import { useNavigate } from 'react-router-dom';
import { useFoodContext } from '../../../context/FoodContext';

const cakes = [
  {
    id: 1,
    name: 'Chocolate Cake',
    description: 'Rich and moist chocolate cake with a creamy chocolate frosting.',
    img: 'https://images.unsplash.com/photo-1652561781059-58d5d9ffcb4d?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 2,
    name: 'Red Velvet Cake',
    description: 'Delicious red velvet cake with a hint of cocoa and cream cheese frosting.',
    img: 'https://images.unsplash.com/photo-1714386148315-2f0e3eebcd5a?q=80&w=1936&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 3,
    name: 'Cheesecake',
    description: 'Classic cheesecake with a buttery graham cracker crust and a rich, creamy filling.',
    img: 'https://images.unsplash.com/photo-1706799419579-592e69a2bf8f?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 4,
    name: 'Carrot Cake',
    description: 'Moist carrot cake with walnuts and a tangy cream cheese frosting.',
    img: 'https://images.unsplash.com/photo-1501437638401-4addcfd56d3d?q=80&w=1886&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 5,
    name: 'Lemon Drizzle Cake',
    description: 'Zesty lemon cake soaked in lemon syrup with a sweet and tangy glaze.',
    img: 'https://images.unsplash.com/photo-1578985545062-69928b1d9587?q=80&w=1868&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 6,
    name: 'Black Forest Cake',
    description: 'Decadent chocolate sponge cake layered with cherries and whipped cream.',
    img: 'https://images.unsplash.com/photo-1606890737304-57a1ca8a5b62?q=80&w=2003&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 7,
    name: 'Vanilla Sponge Cake',
    description: 'Light and fluffy vanilla sponge cake with a rich buttercream frosting.',
    img: 'https://images.unsplash.com/photo-1536599524557-5f784dd53282?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 8,
    name: 'Tiramisu Cake',
    description: 'Coffee-flavored cake layered with mascarpone cheese and cocoa powder.',
    img: 'https://images.unsplash.com/photo-1696487773642-d173e42fcaa1?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 9,
    name: 'Strawberry Shortcake',
    description: 'Light and airy shortcake layered with fresh strawberries and whipped cream.',
    img: 'https://images.unsplash.com/photo-1679654594494-adfeaef21225?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  },
  {
    id: 10,
    name: 'Matcha Cake',
    description: 'Delicate green tea-flavored cake with a smooth matcha frosting.',
    img: 'https://images.unsplash.com/photo-1722478347260-5aba978612ff?q=80&w=1854&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  }
];

const Cakes = () => {
  const navigate = useNavigate();

  const {food,setFood} = useFoodContext();

  const handleNavigation = (cake) => {
    setFood(cake)
    navigate(`/recipe/cakes/${cake.name}`);
  }

  return (
    <section id='cakes' className='cakes'>
      <h2>Our Cakes</h2>
      <p>Indulge in our selection of delicious cakes, perfect for any occasion.</p>
      <div className='cake-items'>
        {cakes.map(cake => (
          <div key={cake.id} className='cake-item' onClick={() => handleNavigation(cake)}>
            <div className='cake-image'>
              <img src={cake.img} alt={cake.name} />
            </div>
            <div className='cake-info'>
              <h3>{cake.name}</h3>
              <p>{cake.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Cakes;
