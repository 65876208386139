import {createContext, useContext, useState, useEffect} from 'react'

const FoodContext = createContext();


export const FoodProvider = ({children})=>{

    const getDataFromStorage = (key, defaultValue) => {
        try {
          const item = localStorage.getItem(key);
          return item ? JSON.parse(item) : defaultValue;
        } catch (error) {
          console.error(`Error parsing ${key} from localStorage`, error);
          return defaultValue;
        }
      };

    const [subCategory,setSubCategory] = useState(() => getDataFromStorage('subCategory', ''));
    const [food,setFood] = useState(()=>getDataFromStorage('food',''))
    
    useEffect(() => {
        localStorage.setItem('food',JSON.stringify(subCategory));
      }, [subCategory]);

      useEffect(() => {
        console.log("Food --->",food);
        localStorage.setItem('food',JSON.stringify(food));
      }, [food]);

    return(
        <FoodContext.Provider value={{subCategory,setSubCategory,food,setFood}}>
            {children}
        </FoodContext.Provider>
        );
}

export const useFoodContext = () => useContext(FoodContext);