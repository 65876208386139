import {createContext, useContext, useState} from 'react';


const LocalContext = createContext();


export const LocalProvider = ({children})=>{
    const [local, setLocal] = useState({
        siteName:'FoodRecipies'
    });
    return(
        <LocalContext.Provider value={{local, setLocal}}>
            {children}
        </LocalContext.Provider>
        );
}

export const useLocalContext = () => useContext(LocalContext);