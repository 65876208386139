import React,{useEffect} from 'react'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from '../view/Home/Home';
import Header from '../view/Header/Header';
import About from '../view/About/About';
import Footer from '../view/Footer/Footer';
import Contact from '../view/Contact/Contact';
import CookieManager from '../CookieManager/CookieManager';
import Terms from '../view/Terms/Terms';
import OpenCategory from '../view/OpenCategory/OpenCategory';
import Recipe from '../view/Recipe/Recipe';
import PrivacyPolicy from '../view/Privacypolicy/PrivacyPolicy';

const Container = () => {
  const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return null;
  };

  return (
    <>
    <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/recipe/:subname" element={<OpenCategory />} />
          <Route path='/recipe/:subname/:recipeName' element={<Recipe />}/>
          <Route exact path="/about" element={<About />}/>
          <Route exact path="/contact" element={<Contact />}/>
          <Route exact path="/terms&condition" element={<Terms />}/>
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />}/>

        </Routes>
        <Footer />
        <CookieManager />
    </Router>
    </>
  )
}

export default Container